import { Injectable } from '@angular/core';
import axios from 'axios';
import { serverUrl } from '@app/global/constants/urls';
import { AlertsService } from './alerts.service';

@Injectable({
  providedIn: 'root',
})
export class CrudService {
  mock: boolean = true;
  baseUrl = this.mock ? 'https://dummyjson.com' : serverUrl;
  ax: any;
  baseOptions = {
    baseURL: serverUrl + '/api/',
    timeout: 50000,
    withCredentials: true,
    withXSRFToken: true,
    xsrfCookieName: 'csrftoken', // default: XSRF-TOKEN
    xsrfHeaderName: 'x-csrftoken', // default: X-XSRF-TOKEN
  };
  pollingInterval: any;
  pollingIntervalTime: number = 5000;
  pollingCount: number = 0;
  pollingRetryCount: number = 15;

  constructor(private alerts: AlertsService) {
    const options = {
      ...this.baseOptions,
      ...{ headers: { 'Content-Type': 'application/json' } },
    };
    this.ax = axios.create(options);
  }

  list(endpoint: string, page: number = 0, params: any = {}): Promise<any> {
    const pageQuery = page ? `?page=${page}` : '';
    return this.ax.get(`${endpoint}/${pageQuery}`, { params });
  }

  get(endpoint: string, id?: number | string, params?: any): Promise<any> {
    const url = endpoint + (id ? `/${id}/` : '/');
    return this.ax.get(url, { params });
  }

  post(endpoint: string, data: any = {}, params?: any): Promise<any> {
    return this.ax.post(`${endpoint}/`, data, { params });
  }

  put(
    endpoint: string,
    id: number | null,
    data: any = {},
    params?: any
  ): Promise<any> {
    if (id === null) {
      return this.ax.put(`${endpoint}/`, data, { params });
    }
    return this.ax.put(`${endpoint}/${id}/`, data, { params });
  }

  patch(endpoint: string, id: number, data: any = {}): Promise<any> {
    return this.ax.patch(`${endpoint}/${id}/`, data);
  }

  getCsv(endpoint: string, id?: string): Promise<any> {
    const options = {
      ...this.baseOptions,
      headers: { 'Content-Type': 'text/csv' },
    };
    const ax = axios.create(options);
    const url = endpoint + (id ? `/${id}/` : '/');
    return ax.get(url, { responseType: 'blob' });
  }

  prepareCsv(type: string) {
    return new Promise((resolve, reject) => {
      this.get('prepare-csv', '', { type: type })
        .then(async (res) => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  pollForCsv(task_id: string) {
    return new Promise((resolve, reject) => {
      this.get('poll-csv', task_id)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  pollTaskStatus(task_id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.pollingInterval = setInterval(() => {
        this.pollForCsv(task_id)
          .then((response: any) => {
            if (response.status === 'PENDING') {
              this.pollingCount++;
              if (this.pollingCount > this.pollingRetryCount) {
                this.clearPolling();
                reject('Request timeout');
              }
            } else if (response.status === 'SUCCESS') {
              this.clearPolling();
              resolve(response);
            } else {
              this.clearPolling();
              reject(response);
            }
          })
          .catch((err) => {
            console.log(err);
            this.clearPolling();
            reject(err);
          });
      }, this.pollingIntervalTime); // Poll every 3 seconds
    });
  }
  // Stop polling
  clearPolling() {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
      this.pollingInterval = null;
      this.pollingCount = 0;
    }
  }
  downloadCsv(task_id: string) {
    return new Promise((resolve, reject) => {
      this.getCsv('download-csv', task_id)
        .then(async (res) => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  postWithFormData(endpoint: string, data: FormData): Promise<any> {
    const options = {
      ...this.baseOptions,
      ...{ headers: { 'Content-Type': 'multipart/form-data' } },
    };
    const ax = axios.create(options);
    return ax.post(`${endpoint}/`, data);
  }

}
