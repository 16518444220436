import { Injectable } from '@angular/core';
import {
  ServiceRequest,
  BasicItem,
  MaterialBasicItem,
} from '@app/global/models/basic';
import { CrudService } from '@app/global/services/crud.service';
import { FormlyFieldsService } from '@app/global/services/formly-fields.service';
import { BehaviorSubject } from 'rxjs';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ProjectService } from './project.service';
import { MaterialService } from './material.service';
import { MaterialRequestsService } from './material-requests.service';
import { PartService } from './part.service';
import { ServiceRequestForm } from '../models/formly-forms';

@Injectable({
  providedIn: 'root',
})
export class ServiceRequestsService {
  public isProjectSelected = new BehaviorSubject(false);
  public selectedType = new BehaviorSubject(null);
  public hasNoOutput = new BehaviorSubject(false);
  public itemDataFetched: boolean = false; // If item data is fetched for dropdowns
  public materialList: MaterialBasicItem[] = [];
  public materialRequestItemsList: BasicItem[] = [];
  public partList: MaterialBasicItem[] = []; // Same type as material 
  public formModel: any = {};
  constructor(
    private crud: CrudService,
    private formServ: FormlyFieldsService,
    private projectServ: ProjectService,
    private matServ: MaterialService,
    private matReqServ: MaterialRequestsService,
    private partServ: PartService,
  ) {}

  /**
   * Get all service requests for the current user.
   * If approverID is provided, then it will return 
   * all requests to be approved by the user/approver.
   * If approverID is null, then it will return all requests
   * based on the user's role.
   * @param approver - Approver ID
   * @param page - Requested page number
   * @returns 
   */
  getAllServiceRequests(    
    page: number,
    sortText?: string,
    searchText?: string,
    filters?: any[]): Promise<ServiceRequest[]> {
    return new Promise((resolve, reject) => {
      let params:any = {
        ordering: sortText,
        search: searchText 
      }
      if (filters && filters.length > 0) {
        filters.forEach((filter) => {
          params[filter.key] = filter.value;
        });
      }
      this.crud.list('servicerequests', page, params).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  getServiceRequestDetails(id: number): Promise<ServiceRequest> {
    return new Promise((resolve, reject) => {
      this.crud.get('servicerequests', id).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  createServiceRequest(request: ServiceRequest) {
    return new Promise((resolve, reject) => {
      this.crud.post('servicerequests', request).then((res) => {
        if (res.status === 201) {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  updateServiceRequest(requestId: number, request: ServiceRequest) {
    return new Promise((resolve, reject) => {
      this.crud.put('servicerequests', requestId, request).then((res) => {
        if (res.status === 200) {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  cancelServiceRequest(requestId: number, remarks: string) {
    return new Promise((resolve, reject) => {
      this.crud.put('servicerequests', requestId, {is_canceled: true, remarks: remarks}).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
    /**
   * Cancel a service request item
   * @param id 
   * @returns 
   */
  cancelServiceRequestItem(id: number, remarks: string) {
    return new Promise((resolve, reject) => {
      this.crud.put('servicerequestitems', id, {is_canceled: true, remarks: remarks}).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  getServiceRequestItem(requestId: number) {
    return new Promise((resolve, reject) => {
      this.crud.get('servicerequestitems', requestId).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  
  /**
   * Update project options.
   * Add hook to check if project is selected.
   * @param projectField 
   * @returns 
   */
  updateProjectFieldForCreate(projectField: FormlyFieldConfig): Promise<FormlyFieldConfig> {
    return new Promise((resolve, reject) => {
      this.projectServ.getOngoingOrNotStartedProjects().then((projects: any) => {
        const project_options = projects.map((project: any) => {
          return project = {...project, subLabel: project.identifier}
        });
        const field = this.formServ.updateDataFieldOptions(
          project_options,
          projectField,
          true
        );
        field.hooks = {
          onInit: (_projectField: any) => {
            if (_projectField.formControl.value) {
              this.isProjectSelected.next(!!projectField.formControl?.value);
            }
            _projectField.formControl.valueChanges.subscribe((selectedProject: any) => {
              this.isProjectSelected.next(!!projectField.formControl?.value);
              this.itemDataFetched = false;
            });
          }
        }
        resolve(field);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  updateTypeFieldForCreate(typeField: FormlyFieldConfig): Promise<FormlyFieldConfig> {
    return new Promise((resolve, reject) => {
      typeField.hooks = {
        onInit: (_typeField: any) => {
          if (_typeField.formControl.value) {
            this.selectedType.next(_typeField.formControl.value);
          }
          _typeField.formControl.valueChanges.subscribe((selectedType: any) => {
            this.selectedType.next(selectedType);
          });
        }
      }
      resolve(typeField);
    });
  }
  /**
   * Fetch all item data for dropdowns.
   * This is done only once in the form,
   * and done at the click of 'Add Item' button.
   * Fetch material data only if the selected type is 'MATERIAL'
   */
  fetchItemData(project?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const promises = [];
      const project_id = project ? project : this.formModel.service_request?.project;
      promises.push(this.partServ.getApprovedPartsForProject(project_id));
      promises.push(this.matServ.getAllProjectApprovedMaterials(project_id));
      promises.push(this.matReqServ.getMaterialRequestItemsForProject(project_id));
      
      Promise.all(promises).then((res: any) => {
        this.partList = res[0].map((part: any) => {
          return {...part, subLabel: part.identifier}
        })
        this.materialList = res[1];
        this.itemDataFetched = true;
        this.materialRequestItemsList = res[2];

        resolve(true);
      });
      
    });
  }
  /**
   * Fill material and material request dropdowns if the selected type is 'MATERIAL' 
   * Fill part dropdown in both cases
   * Disable output part for 'PART' type as it will be copied from input part
   * @param itemField 
   * @returns 
   */
  updateItemFormFields(itemField: FormlyFieldConfig, isEdit: boolean = false): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let inputField = this.formServ.findArrayNestedDataField(itemField, 'inputs');
      let outputField = this.formServ.findArrayNestedDataField(itemField, 'outputs');
      let materialField = this.formServ.findArrayNestedDataField(inputField, 'material');
      let materialRequestField = this.formServ.findArrayNestedDataField(inputField, 'material_request_item');
      let inputPartField = this.formServ.findArrayNestedDataField(inputField, 'part');
      let partBatchField = this.formServ.findArrayNestedDataField(inputField, 'part_batch');
      let outputPartField = this.formServ.findArrayNestedDataField(outputField, 'part');
      
      outputPartField = this.formServ.updateDataFieldOptions(
        this.partList,
        outputPartField,
        true
      );
      this.setOutputPartFieldHooks(outputPartField);

      if(this.selectedType.value === 'MATERIAL'){
        // Update material dropdown
        materialField = this.formServ.updateDataFieldOptions(
          this.materialList,
          materialField,
          true
        );
        // Update material request dropdown and quantity label
        this.setInputMaterialFieldHooks(materialField, isEdit);
        // Make input part and part batch fields not required, hide them
        inputPartField.expressions = {};
        inputPartField.expressions.hide = 'true';
        inputPartField.props.required = false;
        partBatchField.expressions = {};
        partBatchField.expressions.hide = 'true';
        partBatchField.props.required = false;
        // Enable output part field
        // Make Material and Material Request fields required
        materialField.props.required = true;
        materialRequestField.props.required = true;
        delete materialField.expressions;
        delete materialRequestField.expressions;
        outputPartField.props.disabled = false;

      }
      else{
        // Make material and material request fields not required, hide them
        materialField.expressions = {};
        materialField.expressions.hide = 'true';
        materialField.props.required = false;
        materialRequestField.expressions = {};
        materialRequestField.expressions.hide = 'true';
        materialRequestField.props.required = false;
        // outputPartField.props.disabled = true;
        // Make input part and part batch fields required
        inputPartField.props.required = true;
        delete inputPartField.expressions;
        partBatchField.props.required = true;
        delete partBatchField.expressions;
        // Update input part dropdown
        inputPartField = this.formServ.updateDataFieldOptions(
          this.partList,
          inputPartField,
          true
        );
        // Set hooks for input part field
        this.setInputPartFieldHooks(inputPartField, isEdit);
      }


      this.setIsHourlyHooks(itemField);
      resolve(true);
    });
  }
  /**
   * When input material field is changed, update the material request dropdown and quantity label.
   * @param materialField 
   * @param isEdit 
   */
  setInputMaterialFieldHooks(materialField: FormlyFieldConfig, isEdit: boolean) {
    materialField.hooks = {
      onInit: (_materialField: any) => {
        if (_materialField.formControl.value) {
          this.updateMaterialQuantityWithUnit(_materialField, _materialField.formControl.value);
          this.updateMaterialRequestDropdown(_materialField, _materialField.formControl.value, isEdit);
        }
        _materialField.formControl.valueChanges.subscribe((selectedMaterial: any) => {
          this.updateMaterialQuantityWithUnit(_materialField, selectedMaterial);
          this.updateMaterialRequestDropdown(_materialField, selectedMaterial);
        });
      }
    }
  }
  /**
   * When input part field is changed, update the part batch dropdown and quantity label.
   * 1. Update part batch dropdown
   * 2. Update quantity label with unit
   * 3. Copy input part to output part if no_output is not checked
   * @param inputPartField 
   * @param isEdit 
   */
  setInputPartFieldHooks(inputPartField: FormlyFieldConfig, isEdit: boolean) {
    inputPartField.hooks = {
      onInit: (_inputPartField: any) => {
        if (_inputPartField.formControl.value) {
          // Fill part batch dropdown
          this.updatePartBatchDropdown(_inputPartField, _inputPartField.formControl.value, isEdit);

          // Update quantity label with unit
          this.updatePartQuantityWithUnit(_inputPartField, _inputPartField.formControl.value);
        }
        _inputPartField.formControl.valueChanges.subscribe((selectedPart: any) => {
          // Fill part batch dropdown
          this.updatePartBatchDropdown(_inputPartField, selectedPart);

          // Update quantity label with unit
          this.updatePartQuantityWithUnit(_inputPartField, _inputPartField.formControl.value);
          
          // Copy input part to output part
          // if(!this.hasNoOutput.value){ // Copy only if no_output is not checked (dynamic)
          //   const _outputField = this.formServ.findDataFieldInSuperParent(_inputPartField.parent, 'output');
          //   const _outputPartField = this.formServ.findGroupNestedFormField(_outputField, 'part');
          //   _outputPartField.formControl.setValue(selectedPart);
          // }
        });
      }
    }
  }
  /**
   * Set hooks for output part field.
   * When output part field is changed, update the quantity label with unit.
   * @param outputPartField 
   */
  setOutputPartFieldHooks(outputPartField: FormlyFieldConfig) {
    outputPartField.hooks = {
      onInit: (_outputPartField: any) => {
        if (_outputPartField.formControl.value) {
          this.updatePartQuantityWithUnit(_outputPartField, _outputPartField.formControl.value);
        }
        _outputPartField.formControl.valueChanges.subscribe((selectedPart: any) => {
          this.updatePartQuantityWithUnit(_outputPartField, selectedPart);
        });
      }
    }
  }
  /**
   * When is_hourly field is checked, mark hours field as required.
   * If it is unchecked, mark hours field as not required and clear its value.
   * @param itemField 
   */
  setIsHourlyHooks(itemField: FormlyFieldConfig) {
    let isHourlyField = this.formServ.findArrayNestedDataField(itemField, 'is_hourly');
    isHourlyField.hooks = {
      onInit: (_isHourlyField: any) => {
        const _hoursField = this.formServ.findDataFieldInParent(_isHourlyField, 'hours');
        if (_isHourlyField.formControl.value) {
          _hoursField.props.required = true;
        }
        _isHourlyField.formControl.valueChanges.subscribe((value: any) => {
          _hoursField.props.required = !!value;
          if(!value){
            _hoursField.formControl.setValue(null);
          }
        });
      }
    }
  }
  async updatePartBatchDropdown(partField: FormlyFieldConfig, selectedPartId: number, isEdit: boolean = false) {
    let _partBatchField = this.formServ.findDataFieldInParent(partField, 'part_batch');
    if(!isEdit){
      _partBatchField.formControl.setValue(null);
    }

    const partBatches:any = await this.partServ.getPartBatchList(selectedPartId, true);
    const partBatchList = partBatches.map((partBatch: any) => {
      return {
        name: partBatch.name,
        id: partBatch.id,
        subLabel: partBatch.operation,
        subLabel2: partBatch.quantity_left + ' ' + partBatch.part.unit + ' left',
      }
    });

    _partBatchField = this.formServ.updateDataFieldOptions(
      partBatchList,
      _partBatchField,
      true
    );

    if(isEdit){ // force update the field after options are updated
      _partBatchField.formControl.setValue(_partBatchField.formControl.value);
    }
  }
  updateMaterialRequestDropdown(materialField: FormlyFieldConfig, selectedMaterialId: number, isEdit: boolean = false) {
    let _matReqfield = this.formServ.findDataFieldInParent(materialField, 'material_request_item');
    if(!isEdit){
      _matReqfield.formControl.setValue(null);
    }
    let matReqList:any[] = [];
    this.materialRequestItemsList.forEach((matReqItem: any) => {
      if(matReqItem.material?.source === selectedMaterialId){
        matReqList.push({name: matReqItem.material_request.name, id: matReqItem.id, subLabel: `Balance: ${matReqItem?.balance}`});
      }
    });
    _matReqfield = this.formServ.updateDataFieldOptions(
      matReqList,
      _matReqfield,
      true
    );
  }
  /**
   * If no_output field is checked, clear, disable and mark output fields not-required.
   * If it is unchecked, enable output fields and mark required
   */
  toggleOutput(itemField: FormlyFieldConfig) {
    let outputField = this.formServ.findArrayNestedDataField(itemField, 'outputs');
    const outputPartField = this.formServ.findArrayNestedDataField(outputField, 'part');
    const outputQuantityField = this.formServ.findArrayNestedDataField(outputField, 'quantity');

    if(this.hasNoOutput.value){
      outputPartField.props.disabled = true;
      outputQuantityField.props.disabled = true;
      outputPartField.props.required = false;
      outputQuantityField.props.required = false;
      outputPartField.formControl?.setValue(null);
      outputQuantityField.formControl?.setValue(null);
    }else{
      if(this.selectedType.value === 'MATERIAL'){
        outputPartField.props.disabled = false;
      }
      outputQuantityField.props.disabled = false;
      outputPartField.props.required = true;
      outputQuantityField.props.required = true;
    }
  }
  updateMaterialQuantityWithUnit(materialField: any, selectedMaterialId: any) {
    const selectedMaterialIndex = this.materialList.findIndex((material: BasicItem) => material.id === selectedMaterialId);
    if (selectedMaterialIndex !== -1) {
      const quantityField = this.formServ.findDataFieldInParent(materialField, 'quantity');
      quantityField.props.label =  `Quantity (${this.materialList[selectedMaterialIndex].unit})`
    }
  }
  updatePartQuantityWithUnit(partField: any, selectedPartId: any) {
    const selectedPartIndex = this.partList.findIndex((part: BasicItem) => part.id === selectedPartId);
    if (selectedPartIndex !== -1) {
      const quantityField = this.formServ.findDataFieldInParent(partField, 'quantity');
      quantityField.props.label =  `Quantity (${this.partList[selectedPartIndex].unit})`
    }
  }

  resetData(){
    this.formModel = {} as ServiceRequestForm;
    this.materialList = [];
    this.materialRequestItemsList = [];
    this.partList = [];
    this.itemDataFetched = false;
  }
}
