import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  /**
   * Returns the current date in YYYY-MM-DD format.
   * @returns 
   */
  getCurrentDate(dateString: string = new Date().toISOString()) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // January is 0
    const day = date.getDate();
    return `${day}-${month}-${year}`;
  }
  snake_to_title_case(str: string) {
    str = str.toLowerCase();
    return str.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }
  compareDateByDay(date1: Date, date2: Date) {
    // Extract date components (year, month, and day) from each date object
    const year1 = date1.getFullYear();
    const month1 = date1.getMonth();
    const day1 = date1.getDate();
    
    const year2 = date2.getFullYear();
    const month2 = date2.getMonth();
    const day2 = date2.getDate();

    // Compare the date components
    if (year1 === year2 && month1 === month2 && day1 === day2) {
      return 0; // Dates are the same
    } else if (year1 > year2 || (year1 === year2 && month1 > month2) || (year1 === year2 && month1 === month2 && day1 > day2)) {
      const difference = Math.abs(date1.getTime() - date2.getTime());
      return Math.round(difference / (1000 * 3600 * 24)); // date1 is later than date2
    } else {
      return -1; // date1 is earlier than date2
    }
  }
  /**
   * Deep clones an object or array, mainly for cloning formly fields.
   * @param field The object or array to clone.
   * @returns A deep clone of the input object or array.
   */
  deepCloneField(field: any): any {
    if (typeof field !== 'object' || field === null) return field;
  
    const copy: any = Array.isArray(field) ? [] : {};
  
    for (const key in field) {
      if (field.hasOwnProperty(key)) {
        copy[key] =
          typeof field[key] === 'object' && field[key] !== null
            ? this.deepCloneField(field[key]) // Recursive deep copy
            : field[key]; // Copy primitive values and functions
      }
    }
    return copy;
  }
}
