import { Injectable } from '@angular/core';
import { CrudService } from '@app/global/services/crud.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  public notifications: any = {};
  constructor(
    private crud: CrudService
  ) { }

  getNotificationSummary() {
    return new Promise((resolve, reject) => {
      this.crud.get('notifications').then((res) => {
        if (res.status === 200) {
          this.notifications = res.data;
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      })
    });
  }

  getNotifications(pageNumber?: number, type?: string, read?: boolean) { 
    return new Promise((resolve, reject) => {
      const page = pageNumber || 1;
      const params:any = {};
      if (type) {
        params['type'] = type;
      }
      if (read !== undefined) {
        params['read'] = read;
      }
      this.crud.list('notifications', page, params).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  markRead(id: number, read: boolean) {
    return new Promise((resolve, reject) => {
      this.crud.patch('notifications', id, {read: read}).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
}
