import { Injectable } from '@angular/core';
import { SharedVarsService } from '@app/global/services/shared-vars.service';
import { BasicItem, InventoryOutRequest, MaterialRequestItem } from '@app/global/models/basic';
import { CrudService } from '@app/global/services/crud.service';
import { BehaviorSubject } from 'rxjs';
import { FormlyFieldsService } from '@app/global/services/formly-fields.service';
import { UtilsService } from './utils.service';
import { MaterialService } from './material.service';
import { MaterialRequestsService } from './material-requests.service';
import { InventoryOutRequestForm } from '../models/formly-forms';
import { PartService } from './part.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryOutRequestService {
    public isInventoryOutRequestSelected = new BehaviorSubject(false);
    public showDeleteAlert = false;
    public showDeleteButton = true;
    public selectedInventoryOut = 0;
    public selectedInventoryOutRequestItem: any = [];
    public selectedInventoryOutRequest: any = {};
    public selectedProject = new BehaviorSubject(0);
    public selectedType = new BehaviorSubject('');
    public itemDataFetched: boolean = false;
    private materialList: any;
    private materialRequestItemsList: any;
    private partList: any;
    private partBatchList: any;
    // public formModel: any = {};

    constructor(private sharedVars: SharedVarsService,
        private crud: CrudService,
        private formServ: FormlyFieldsService,
        private utils: UtilsService,
        private matServ: MaterialService,
        private matReqServ: MaterialRequestsService,
        private partServ: PartService,
        private general: GeneralService,
      ) {}


  getAllInvOutRequests(
    page: number,
    sortText?: string,
    searchText?: string,
    filters?: any[], 
  ): Promise<InventoryOutRequest[]> {
    return new Promise((resolve, reject) => {
      let params:any = {
        ordering: sortText,
        search: searchText 
      }
      if (filters && filters.length > 0) {
        filters.forEach((filter) => {
          params[filter.key] = filter.value;
        });
      }
      this.crud
        .list('inventoryoutrequests', page, params)
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getInvOutRequestDetails(id: number): Promise<InventoryOutRequest> {
    return new Promise((resolve, reject) => {
      this.crud.get('inventoryoutrequests', id).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  createInvOutRequest(request: InventoryOutRequest) {
    return new Promise((resolve, reject) => {
      this.crud.post('inventoryoutrequests', request).then((res) => {
        if (res.status === 201) {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  updateInvOutRequest(requestId: number, request: InventoryOutRequest) {
    return new Promise((resolve, reject) => {
      this.crud.put('inventoryoutrequests', requestId, request).then((res) => {
        if (res.status === 200) {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }


  cancelInvOutRequest(requestId: number, remarks: string) {
    return new Promise((resolve, reject) => {
      this.crud.patch('inventoryoutrequests', requestId, {is_canceled: true, remarks: remarks}).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  /**
   * When an inv-out request is edited, generate a list of all
   * material request items from the API response to pre-populate
   * the material request item field options in the form items.
   * When the form is populated with actual values, one of these
   * options will be shown as selected. Post that, the materialField.hooks
   * will update each item's material_request_item field with the actual 
   * material request item.
   * @param data 
   * @returns 
   */
  prePopulateMatReqItemOptions(data:any) {
    return data.map((item: any) => {
      return {name: item.material_request_item.material_request.name, id: item.material_request_item.id};
    });
  }



  /**
   * Details of a material request item
   * @param materialRequestItemId 
   * @returns 
   */
  getMaterialRequestItemDetails(materialRequestItemId: number): Promise<MaterialRequestItem>{
    return new Promise((resolve, reject) => {
      this.crud.get('materialrequestitems', materialRequestItemId).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  /**
   * Cancel an inventory out request item
   * @param id 
   * @returns 
   */
  cancelInventoryOutRequestItem(id: number, remarks: string) {
    return new Promise((resolve, reject) => {
      this.crud.put('inventoryoutrequestitems', id, {is_canceled: true, remarks: remarks}).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  /**
   * When a material is selected, update the material request item
   * field options with the material request items for the selected
   * material and project.
   * When editing, the material request item field should be pre-populated
   * with the actual value.
   * @param materialField 
   * @param edit 
   */
  async updateMaterialRequestOptions(materialField: any, edit: boolean = false) {
    let _matReqItemOptions: any[] = [];
    let materialRequestItemField = this.formServ.findFormFieldInParent(materialField, 'material_request_item');
    const _projectField = this.formServ.findFormFieldInArrayParent(materialField, 'project');
    if(materialField.formControl.value){
      if(_projectField.formControl.value){
        const material_request_items: any[] = await this.matReqServ.getMaterialRequestItemsForProject(_projectField.formControl.value, materialField.formControl.value);
        if(material_request_items){
          _matReqItemOptions = material_request_items.map((item) => {
            return {
              name: item.material_request.name, 
              id: item.id,
              subLabel: item.quantity+' '+item.material.unit+ ' | '+ 'Raised on : '+ this.utils.getCurrentDate(item.created_at)
            };
          });
        }
      }
    }
    materialRequestItemField = this.formServ.updateDataFieldOptions(_matReqItemOptions, materialRequestItemField, true);
    if(!edit){
      materialRequestItemField.formControl.setValue(null);
    }
  }

  /**
   * When a material and material_request_item is selected,
   * set the subtext and details field values for the material_request_item.
   * "details" is a JSON object that contains the material, mmid, vendor, 
   * quantity_allotted and quantity_balance. This is used to compare the
   * quantity requested in the inventory out request with the quantity
   * allotted balance in the material request item.
   * @param materialRequestItemField 
   */
  async setMaterialRequestItemDetails(materialRequestItemField: any) {
    let subtextField = this.formServ.findFormFieldInSuperParent(materialRequestItemField, 'subtext');
    let detailsField = this.formServ.findFormFieldInSuperParent(materialRequestItemField, 'material_request_item_details');
    let subtext = null;
    let material_request_details:any;
    if(materialRequestItemField.formControl.value){
      const materialRequestDetails = await this.getMaterialRequestItemDetails(materialRequestItemField.formControl.value);
      const approved_inv_out_request_items = materialRequestDetails.inventory_out_request_items?.filter((item:any) => item?.inventory_out_request?.approval?.status === 'APPROVED');
      const inv_out_request_quantity = approved_inv_out_request_items ? 
        approved_inv_out_request_items.reduce((acc, inv_out) => acc + inv_out.quantity, 0) : 0;
      material_request_details = {
        material: materialRequestDetails.material.name,
        mmid: materialRequestDetails.mmid ? materialRequestDetails.mmid.name : '',
        vendor: materialRequestDetails.vendor ? materialRequestDetails.vendor.name : '',
        quantity_allotted: materialRequestDetails.quantity,
        quantity_balance: materialRequestDetails.quantity - inv_out_request_quantity
      }
      if(materialRequestDetails){
        subtext = `Material : ${material_request_details.material} \n` +
        `Mmid : ${material_request_details.mmid} \n` +
        `Vendor : ${material_request_details.vendor} \n` +
        `Quantity allotted : ${material_request_details.quantity_allotted} ${materialRequestDetails.material.unit} \n` +
        `Quantity balance : ${material_request_details.quantity_balance} ${materialRequestDetails.material.unit}`;
      }
    }
    subtextField.formControl.setValue(subtext);
    subtextField.hideExpression = !subtext;
    detailsField.formControl.setValue(JSON.stringify(material_request_details));
  }

  /**
   * Fetch all item data for dropdowns.
   * This is done only once in the form,
   * and done at the click of 'Add Item' button.
   * Fetch material data only if the selected type is 'MATERIAL'
   */
  fetchItemData(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const promises = [];
      const project = this.selectedProject.value;
      if(this.selectedType.value === 'MATERIAL'){
        promises.push(this.matServ.getAllProjectApprovedMaterials(project));
        promises.push(this.matReqServ.getMaterialRequestItemsForProject(project));
        Promise.all(promises).then((res: any) => {
          this.materialList = res[0].map((material: any) => {
            return {...material, subLabel: material.identifier}
          });
          this.materialRequestItemsList = res[1];
          this.itemDataFetched = true;
          resolve(true);
        });
      }
      else{
        this.partServ.getApprovedPartsForProject(project).then((res: any) => {
          this.partList = res.map((part: any) => {
            return {...part, subLabel: part.identifier}
          })
          this.itemDataFetched = true;
          resolve(true);
        });
      }
    })
  }

  /**
   * Fill material and material request dropdowns if the selected type is 'MATERIAL' 
   * Fill part dropdown if the selected type is 'PART'
   * @param itemField 
   * @returns 
   */
  updateItemFormFields(itemField: FormlyFieldConfig, isEdit: boolean = false): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let materialField = this.formServ.findArrayNestedDataField(itemField, 'material');
      let materialRequestField = this.formServ.findArrayNestedDataField(itemField, 'material_request_item');
      let partField = this.formServ.findArrayNestedDataField(itemField, 'part');
      let partBatchField = this.formServ.findArrayNestedDataField(itemField, 'part_batch');

      if(this.selectedType.value === 'MATERIAL'){
        
        materialField.props.required = true;
        delete materialField.expressions;
        materialField = this.formServ.updateDataFieldOptions(
          this.materialList,
          materialField,
          true
        );
        materialField.hooks = {
          onInit: (_materialField: any) => {
            if (_materialField.formControl.value) {
              this.updateMaterialQuantityWithUnit(_materialField, _materialField.formControl.value);
              this.updateMaterialRequestDropdown(_materialField, _materialField.formControl.value, isEdit);
            }
            _materialField.formControl.valueChanges.subscribe((selectedMaterial: any) => {
              this.updateMaterialQuantityWithUnit(_materialField, selectedMaterial);
              this.updateMaterialRequestDropdown(_materialField, selectedMaterial);
            });
          }
        }
        materialRequestField.hooks = {
          onInit: (_matReqField: any) => {
            const quantityField = this.formServ.findFormFieldInParent(_matReqField, 'quantity');
            const subtextField = this.formServ.findFormFieldInSuperParent(_matReqField, 'subtext');
            if (_matReqField.formControl.value) {
              subtextField.expressions['hide'] = 'false';
              this.updateSubText(_matReqField.formControl.value, subtextField);
              // this.setQuantityMaxLimit(quantityField, _matReqField.formControl.value);
            }
            _matReqField.formControl.valueChanges.subscribe((selectedMatReqItem: any) => {
              // Set quantityField max limit
              if (selectedMatReqItem){
                subtextField.expressions['hide'] = 'false';
                this.updateSubText(selectedMatReqItem, subtextField);
                // this.setQuantityMaxLimit(quantityField, selectedMatReqItem);
              }
              else{
                delete quantityField.props.max;
                subtextField.formControl.setValue(null);
                subtextField.expressions['hide'] = 'true';
              }
            });
          }
        }

        partField.expressions = {};
        partField.expressions.hide = 'true';
        partField.props.required = false;

        partBatchField.expressions = {};
        partBatchField.expressions.hide = 'true';
        partBatchField.props.required = false;

        materialRequestField.props.required = true;
        delete materialRequestField.expressions;

      }
      else{
        materialField.expressions = {};
        materialField.expressions.hide = 'true';
        materialField.props.required = false;

        materialRequestField.expressions = {};
        materialRequestField.expressions.hide = 'true';
        materialRequestField.props.required = false;

        partBatchField.props.required = true;
        delete partBatchField.expressions;
        
        partField.props.required = true;
        delete partField.expressions;
        partField = this.formServ.updateDataFieldOptions(
          this.partList,
          partField,
          true
        );
        partField.hooks = {
          onInit: (_partField: any) => {
            if (_partField.formControl.value) {
              // Fill part batch dropdown
              this.updatePartBatchDropdown(_partField, _partField.formControl.value, isEdit);

              // Update quantity label with unit
              this.updatePartQuantityWithUnit(_partField, _partField.formControl.value);
            }
            _partField.formControl.valueChanges.subscribe((selectedPart: any) => {
              // Fill part batch dropdown
              this.updatePartBatchDropdown(_partField, selectedPart);

              // Update quantity label with unit
              this.updatePartQuantityWithUnit(_partField, _partField.formControl.value);
            });
          }
        }
      }

      resolve(true);
    });
  }

  /**
   * When a material request item is selected, set the max limit for the quantity field
   * as the balance of the material request item.
   * @param quantityField 
   * @param selectedMatReqItemId 
   */
  setQuantityMaxLimit(quantityField: any, selectedMatReqItemId: any) {
    const selectedMatReqItemDetails = this.materialRequestItemsList.find((item: any) => item.id === selectedMatReqItemId);
    if (selectedMatReqItemDetails) {
      quantityField.props = {
        ...quantityField.props,
        max: selectedMatReqItemDetails.balance
      }
    }
  }
  /**
   * Get stock of the selected material for selected project
   * - General and Reserved stock 
   * @param selectedMaterialId 
   * @param projectId
   */
  updateSubText(selectedMatReqItemId:number, subtextField: FormlyFieldConfig) {
    const selectedMatReqItemDetails = this.materialRequestItemsList.find((item: any) => item.id === selectedMatReqItemId);
    if (!selectedMatReqItemDetails) {
      return;
    }
    const payload = {
      material: selectedMatReqItemDetails.material.source,
      mmid: selectedMatReqItemDetails.mmid?.source,
      vendor: selectedMatReqItemDetails.vendor?.source,
      project: this.selectedProject.value
    }
    this.general.getStockData(payload).then((stock: any) => {
      const stockText = `Total allotted to use: ${selectedMatReqItemDetails.quantity} | Remaining to use: ${selectedMatReqItemDetails.balance}`+
      `\nStock for given Material - General Stock: ${stock.stock?.general} | Reserved for this project: ${stock.stock?.reserved}` +
      `\nMaximum Inv-out quantity: ${Math.min(selectedMatReqItemDetails.balance, stock.stock?.general + stock.stock?.reserved)}`;
      subtextField.formControl?.setValue(stockText);
    });
  }

  updateMaterialRequestDropdown(materialField: FormlyFieldConfig, selectedMaterialId: number, isEdit: boolean = false) {
    let _matReqfield = this.formServ.findDataFieldInParent(materialField, 'material_request_item');
    if(!isEdit){
      _matReqfield.formControl.setValue(null);
    }
    let matReqList:any[] = [];
    this.materialRequestItemsList.forEach((matReqItem: any) => {
      if(matReqItem.material?.source === selectedMaterialId){
        matReqList.push({name: matReqItem.material_request.name, id: matReqItem.id, subLabel: `Balance: ${matReqItem.balance} ${matReqItem.material?.unit}`});
      }
    });
    _matReqfield = this.formServ.updateDataFieldOptions(
      matReqList,
      _matReqfield,
      true
    );
  }

  async updatePartBatchDropdown(partField: FormlyFieldConfig, selectedPartId: number, isEdit: boolean = false) {
    let _partBatchField = this.formServ.findDataFieldInParent(partField, 'part_batch');
    if(!isEdit){
      _partBatchField.formControl.setValue(null);
    }

    const partBatches:any = await this.partServ.getPartBatchList(selectedPartId, true);
    const partBatchList = partBatches.map((partBatch: any) => {
      return {
        name: partBatch.name,
        id: partBatch.id,
        subLabel: partBatch.operation,
        subLabel2: partBatch.quantity_left + ' ' + partBatch.part.unit + ' left',
      }
    });

    _partBatchField = this.formServ.updateDataFieldOptions(
      partBatchList,
      _partBatchField,
      true
    );

    if(isEdit){ // force update the field after options are updated
      _partBatchField.formControl.setValue(_partBatchField.formControl.value);
    }
  }

  updateMaterialQuantityWithUnit(materialField: any, selectedMaterialId: any) {
    const selectedMaterialIndex = this.materialList.findIndex((material: BasicItem) => material.id === selectedMaterialId);
    if (selectedMaterialIndex !== -1) {
      const quantityField = this.formServ.findDataFieldInParent(materialField, 'quantity');
      quantityField.props.label =  `Quantity (${this.materialList[selectedMaterialIndex].unit})`
    }
  }
  updatePartQuantityWithUnit(partField: any, selectedPartId: any) {
    const selectedPartIndex = this.partList.findIndex((part: BasicItem) => part.id === selectedPartId);
    if (selectedPartIndex !== -1) {
      const quantityField = this.formServ.findDataFieldInParent(partField, 'quantity');
      quantityField.props.label =  `Quantity (${this.partList[selectedPartIndex].unit})`
    }
  }
  resetData(){
    // this.formModel = {} as InventoryOutRequestForm;
    this.materialList = [];
    this.materialRequestItemsList = [];
    this.partList = [];
    this.partBatchList = [];
    this.itemDataFetched = false;
    this.selectedProject.next(0);
    this.selectedType.next('');
  }
}