import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/global/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '@app/global/services/session.service';
import { NotificationsService } from '@app/global/services/notifications.service';
import { AlertsService } from '@app/global/services/alerts.service';
import { StockCheckerComponent } from '../stock-checker/stock-checker.component';
import { MenuController } from '@ionic/angular';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  
  constructor(
    private auth: AuthService,
    private router: Router,
    private sessionServ: SessionService,
    public notifServ: NotificationsService,
    public alerts: AlertsService,
    private menu: MenuController
  ) {}

  ngOnInit() {}
  goToInbox(){
    this.router.navigate(['inbox'], { replaceUrl: true });
  }
  goToSettings(){
    this.router.navigate(['settings'], { replaceUrl: true });
  }
  logout() {
    this.auth.logout().then((res) => {
      this.sessionServ.stopSession();
    })
    .catch((err) => {
      console.log(err);
    });
  }
  showStockChecker(){
    this.alerts.presentResolvableGeneralModal(
      StockCheckerComponent,
      'stock-checker-modal',
      false
    ).then(() => {
    });
  }

  toggleMenu(){
    this.menu.toggle();
  }
}
