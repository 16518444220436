<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" type="overlay" *ngIf="auth.loggedIn && !sharedVars.HIDE_MENU" class="menu-pane-visible">
      <ion-content>
        <ion-list class="header-list">
          <ion-item lines="none">
            <ion-label>
              <h2>ASTRA</h2>
              <p>{{sharedVars.USER_DETAIL?.email}}</p>
            </ion-label>
            <ion-button fill="clear" slot="end" color="light" class="mobile-only" (click)="toggleMenu()">
              <ion-icon slot="icon-only" name="close"></ion-icon>
            </ion-button>
          </ion-item>
        </ion-list>
        <div class="scroller">
          <ion-list class="nested" *ngFor="let item of sideMenuPages | keyvalue:keepOriginalOrder">
            <ion-list-header *ngIf="item.key" class="light-header">{{item.key}}</ion-list-header>
            <div auto-hide="false" *ngFor="let p of item.value; let i = index">
              <ion-item 
                routerDirection="root" 
                [id]="p.url+'_link'" 
                [routerLink]="'/'+[p.url]" 
                lines="none" 
                detail="false"
                routerLinkActive="selected" 
                class="sub-menu-item" 
                [disabled]="p.disabled || (p.title == 'Dashboard' && !sharedVars.USER_DETAIL?.is_admin)"
                (click)="toggleMenu()">
                <ion-icon aria-hidden="true" slot="start" [ios]="p.icon + '-outline'"
                  [md]="p.icon + '-sharp'">
                </ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </div>
          </ion-list>
        </div>
        <p class="version">Version: {{sharedVars.META_DATA?.version}}</p>
      </ion-content>
    </ion-menu>
    <div class="non-sidemenu-content" id="main-content" [ngClass]="{'logged-in': auth.loggedIn, 'for-print': sharedVars.HIDE_MENU}">
      <app-header *ngIf="auth.loggedIn && !sharedVars.HIDE_MENU"></app-header>
      <ion-router-outlet></ion-router-outlet>
    </div>
  </ion-split-pane>
  <div class="global-loader" *ngIf="loader.show">
    <ion-spinner name="crescent" color="light"></ion-spinner>
    <ion-text>{{loader.text}}</ion-text>
  </div>
  <div class="global-banner" *ngIf="sharedVars.TEST_MODE">
    <div class="content">
      <p class="ion-text-center">ERP is currently in Test mode. All changes made in this duration will be cleared.</p>
    </div>
  </div>
</ion-app>