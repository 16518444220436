import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DisabledModules, GuardsService, AdminGuard } from './global/guards/guard.service';
import { LoginPage } from './pages/login/login.page';
const routes: Routes = [

  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginPage
  },
  {
    path: 'password-reset-confirm/:uid/:token',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'dashboard',
    canActivate: [GuardsService],
    loadChildren: () => import('@app/pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'vendors',
    canActivate: [GuardsService], 
    loadChildren: () => import('@app/pages/vendors/vendors.module').then( m => m.VendorsPageModule)
  },
  {
    path: 'mmids',
    canActivate: [GuardsService], 
    loadChildren: () => import('@app/pages/mmid/mmid.module').then( m => m.MmidPageModule)
  },
  {
    path: 'purchase-orders',
    canActivate: [GuardsService], 
    loadChildren: () => import('@app/pages/purchase-orders/purchase.module').then( m => m.PurchasePageModule)
  },
  {
    path: 'manufacturers',
    canActivate: [GuardsService], 
    loadChildren: () => import('@app/pages/manufacturer/manufacturer.module').then( m => m.ManufacturerPageModule)
  },
  {
    path: 'materials',
    canActivate: [GuardsService], 
    loadChildren: () => import('@app/pages/materials/materials.module').then( m => m.MaterialsPageModule)
  },
  {
    path: 'inventory-ins',
    canActivate: [GuardsService], 
    loadChildren: () => import('@app/pages/inventory-in/inventory-in.module').then( m => m.InventoryInPageModule)
  },
  {
    path: 'inventory-outs',
    canActivate: [GuardsService], 
    loadChildren: () => import('@app/pages/inventory-out/inventory-out.module').then( m => m.InventoryOutPageModule)
  },
  {
    path: 'users',
    canActivate: [GuardsService], 
    loadChildren: () => import('@app/pages/users/users.module').then( m => m.UsersPageModule)
  },
  {
    path: 'projects',
    canActivate: [GuardsService], 
    loadChildren: () => import('@app/pages/projects/projects.module').then( m => m.ProjectsPageModule)
  },
  {
    path: 'purchase-requests',
    canActivate: [GuardsService], 
    loadChildren: () => import('@app/pages/purchase-requests/purchase-requests.module').then( m => m.PurchaseRequestsModule)
  },
  {
    path: 'material-requests',
    canActivate: [GuardsService], 
    loadChildren: () => import('@app/pages/material-requests/material-requests.module').then( m => m.MaterialRequestsModule)
  },
  {
    path: 'inventory-out-requests',
    canActivate: [GuardsService],
    loadChildren: () => import('@app/pages/inventory-out-requests/inventory-out-requests.module').then( m => m.InventoryOutRequestsModule)
  },
  {
    path: 'reserved-inventory',
    canActivate: [GuardsService],
    loadChildren: () => import('@app/pages/reserved-inventory/reserved-inventory.module').then( m => m.ReservedInventoryModule)
  },
  {
    path: 'inbox',
    canActivate: [GuardsService], 
    loadChildren: () => import('@app/pages/inbox/inbox.module').then( m => m.InboxPageModule)
  },
  {
    path: 'payments',
    canActivate: [GuardsService], 
    loadChildren: () => import('./pages/payments/payments.module').then( m => m.PaymentsPageModule)
  },
  {
    path: 'print',
    canActivate: [GuardsService], 
    loadChildren: () => import('./pages/print/print.module').then( m => m.PrintModule)
  },
  {
    path: 'parts',
    canActivate: [GuardsService], 
    loadChildren: () => import('./pages/parts/parts.module').then( m => m.PartsPageModule)
  },
  {
    path: 'service-requests',
    canActivate: [GuardsService], 
    loadChildren: () => import('./pages/service-requests/service-requests.module').then( m => m.ServiceRequestsPageModule)
  },
  {
    path: 'settings',
    canActivate: [GuardsService], 
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: '**',
    canActivate: [GuardsService], 
    loadChildren: () => import('@app/pages/not-found/not-found.module').then( m => m.NotFoundPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
