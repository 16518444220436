import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { AlertsService } from '@app/global/services/alerts.service';
import { GeneralService } from '@app/global/services/general.service';
import { SharedVarsService } from '@app/global/services/shared-vars.service';
import { CrudService } from '@app/global/services/crud.service';
@Component({
  standalone: true,
  imports: [CommonModule, IonicModule],
  selector: 'docs-wrapper',
  templateUrl: './docs-wrapper.component.html',
  styleUrls: ['./docs-wrapper.component.scss'],
})
export class DocsWrapperComponent implements OnInit {
  CHUNK_SIZE = 5 * 1024 * 1024;
  @Input() canAddDocs: boolean;
  @Input() canEditDocs: boolean;
  @Input() title: string;
  @Input() docs: any;
  @Input() type: string = 'general';
  @Input() parentId: number; // Parent where docs are uploaded
  @Input() model: string; // Model where docs are uploaded
  @Input() layout: string = 'list'; // list or card
  @Input() childIndex: number = 0; // Including this for cases where docs are uploaded in an ngFor child component
  @Output() afterUpload = new EventEmitter<any>();
  @ViewChild('docUpload', { static: false }) DocFileInputVariable: ElementRef;
  @Output() afterRemove = new EventEmitter<any>();
  uploadLoader: boolean = false;
  uploadProgress: number = 0;
  constructor(
    private alerts: AlertsService, 
    private general: GeneralService,
    private sharedVars: SharedVarsService,
    private crud: CrudService
  ) {}

  ngOnInit() {}
  
  async deleteFileEvent(id: number) {
    const decision = await this.alerts.showPromisableAlert(
      'Delete uploaded document',
      'This action is irreversible. Are you sure you want to delete this document?',
      true,
      'Delete',
      'Cancel',
      'danger',
      'medium'
    );
    if (decision) {
      this.removeDoc(id);
    }
  }
  handleDocUpload(e: any) {
    this.uploadLoader = true;
    var doc = e.target.files[0];
    if (doc && doc.size > this.sharedVars.FILE_SIZE_LIMIT*1024*1024) {
      this.alerts.showToast(`File size should not exceed ${this.sharedVars.FILE_SIZE_LIMIT}MB`, 'bottom', 3000, 'danger');
      this.uploadLoader = false;
      return;
    }
    this.DocFileInputVariable.nativeElement.value = '';
    this.uploadMultiChunkFile(doc, this.parentId, this.model, this.type)
      .then((res: any) => {
        this.alerts.showToast('Document uploaded successfully');
        this.afterUpload.emit(res.data);
      })
      .catch((err) => {
        this.alerts.showApiErrorToast(err);
      })
      .finally(() => {
        this.uploadLoader = false;
      });
  }
  removeDoc(id: number){
    this.general.removeFile(id).then((res) => {
      this.alerts.showToast('Document removed successfully');
      this.afterRemove.emit(id);
    })
    .catch((err) => {
      this.alerts.showApiErrorToast(err);
    });
  }
  async uploadMultiChunkFile(file: File, id: number, model: string, type: string) {
    return new Promise(async (resolve, reject) => {
      const data = {
          id: id,
          model: model,
          type: type
        }
      try {
        // Step 1: Initiate multipart upload
        let initResponse = await this.crud.post('docs/imu', {
          file_name: file.name,
          model: data.model,
          content_type: file.type,
        });

        let { upload_id, file_path } = initResponse.data;
        // Step 2: Upload file in chunks
        let chunkSize = this.CHUNK_SIZE;
        // let parts = [];
        let totalChunks = Math.ceil(file.size / chunkSize);

        const fileChunks = [];
        let uploadedChunks = 0;
        for (let i = 0; i < totalChunks; i++) {
          fileChunks.push(file.slice(i * chunkSize, (i + 1) * chunkSize));
        }
        this.uploadProgress = 0.05; // 5% progress at start
        const uploadPromises = fileChunks.map(async (chunk, index) => {
          let formData = new FormData();
          formData.append('upload_id', upload_id);
          formData.append('file_path', file_path);
          formData.append('part_number', (index + 1).toString());
          formData.append('file', chunk);
          const response = await this.crud.postWithFormData("docs/upload-part", formData);
          uploadedChunks++;
          this.uploadProgress = Math.round((uploadedChunks / totalChunks) * 100)/100;
          return response.data; // Store the ETag & part info
        });
        
        const uploadedPartsInfo = await Promise.all(uploadPromises); // Wait for all to complete
        const parts = uploadedPartsInfo.map((part: any) => ({
          PartNumber: part.part_number,
          ETag: part.etag,
        }));

        // Step 3: Complete multipart upload
        const fullData = {
          ...data,
          file_path: file_path,
          file_name: file.name,
          upload_id: upload_id,
          parts: parts,
        }
        await this.crud.post('docs/cmu', fullData);
        resolve({ message: 'File uploaded successfully' });
      } catch (error) {
        console.error('Upload failed:', error);
        reject(error);
      }
    });
  }
}
