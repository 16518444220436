import { Injectable } from '@angular/core';
import { CrudService } from './crud.service';
import { ExtraServerSetting, ServiceRequestItemOutput } from '@app/global/models/basic';
import { SharedVarsService } from './shared-vars.service';
import { MaterialService } from './material.service';
import { UserService } from './user.service';
import { FormlyFieldsService } from './formly-fields.service';
import { AlertsService } from './alerts.service';
import { NotificationsService } from './notifications.service';
import { StockCheckerFormModel } from '../models/formly-forms';
@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  constructor(private crud: CrudService,
    private sharedVars: SharedVarsService,
    private matServ: MaterialService,
    private userServ: UserService,
    private formServ: FormlyFieldsService,
    private alerts: AlertsService,
    private notifServ: NotificationsService,
    private crudServ: CrudService
    ) {}

  // upload file using api
  uploadFile(file: any, id: number, model: string, type: string) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file, file.name);
      formData.append('id', id.toString());
      formData.append('model', model);
      formData.append('type', type);
      this.crud.postWithFormData('docs', formData).then((res: any) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  removeFile(id: number) {
    return new Promise((resolve, reject) => {
      this.crud.patch('docs', id, { is_deleted: true }).then((res: any) => {
        resolve(res);
      });
    });
  }
  uploadFileToModel(file: any, id: number, model: string, type: string) {
    return this.uploadFile(file, id, model, type);
  }
  getContentTypes() {
    return new Promise((resolve, reject) => {
      this.crud.list('content-types').then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      });
    });
  }
  getExtraServerSettings(): Promise<ExtraServerSetting[]> {
    return new Promise((resolve, reject) => {
      this.crud.list('extra-settings').then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      });
    });
  }
  getSystemServerSettings(): Promise<ExtraServerSetting[]> {
    return new Promise((resolve, reject) => {
      this.crud.list('system-settings').then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      });
    });
  }
  testActions(action:string){
    return new Promise((resolve, reject) => {
      this.crud.post('system-settings', {'action': action}).then((res) => {
        console.log(res);
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      })
    });
  }

  getAllHsnCodes() {
    return new Promise((resolve, reject) => {
      this.crud.get('hsn').then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      })
    })
  }
  getAllGstRates() {
    return new Promise((resolve, reject) => {
      this.crud.get('gst-rates').then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      })
    })
  }
  getCurrencies() {
    return new Promise((resolve, reject) => {
      this.crud.list('currencies').then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      })
    })
  }
  /**
   * Load all initial data from APIs which is required
   * to be loaded before the app is ready.
   * This includes data that fills dropdown, userData etc.
   * This is called from Route GuardService and on Login.
   */
  loadInitialApiData() {
    return new Promise((resolve, reject) => {
      if (
        this.sharedVars.MATERIAL_CATEGORIES && this.sharedVars.MATERIAL_CATEGORIES.length &&
        this.sharedVars.MATERIAL_UNITS && this.sharedVars.MATERIAL_UNITS.length &&
        this.sharedVars.LEAVE_TYPES && this.sharedVars.LEAVE_TYPES.length && 
        this.sharedVars.DEPARTMENTS && this.sharedVars.DEPARTMENTS.length
      ) {
        return resolve(true);
      }
      this.sharedVars.GLOBAL_LOADER.text = 'Loading initial data...';
      this.sharedVars.GLOBAL_LOADER.show = true;
      const materialCategories = this.matServ.getMaterialCategories();
      const materialUnits = this.matServ.getMaterialUnits();
      const leaveTypes = this.userServ.getLeaveTypes();
      const departments = this.userServ.getDepartments();
      const hsnCodes = this.getAllHsnCodes();
      const notifications = this.notifServ.getNotificationSummary();
      const gstRates = this.getAllGstRates();
      const currencies = this.getCurrencies();

      // stop loader after API response is received
      Promise.all([materialCategories, materialUnits, leaveTypes, departments, hsnCodes, notifications, gstRates, currencies])
        .then((res: any) => {
          if (res) {
            this.sharedVars.MATERIAL_CATEGORIES = res[0];
            this.sharedVars.MATERIAL_UNITS = res[1];
            this.sharedVars.LEAVE_TYPES = res[2];
            this.sharedVars.DEPARTMENTS = res[3];
            this.sharedVars.HSN_CODES = res[4];
            this.notifServ.notifications = res[5];
            this.sharedVars.GST_RATES = res[6];
            this.sharedVars.CURRENCIES = {};
            res[7].forEach((currency: any) => {
              this.sharedVars.CURRENCIES[currency.name] = currency.symbol;
            });
          }
          this.sharedVars.GLOBAL_LOADER.show = false;
          this.formServ.updateBasicFieldOptions();
          resolve(true);
        })
        .catch((err) => {
          console.log(err);
          this.alerts.showToast('Error loading initial data', 'bottom', 3000, 'danger');
          reject(false);
        });
    });
  }

  convertArrayToObject(array: ExtraServerSetting[]){
    const obj: any = {};
    array.forEach((item) => {
      obj[item.key] = item;
    });
    return obj;
  }

  getMetaData() {
    this.crudServ.get('metadata').then((res) => {
      this.sharedVars.META_DATA = res.data;
    });
  }

  getFormattedDate(stringDate: string, format?: string ) {
    const date = new Date(stringDate);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    const formattedDate = format!=='user' ? `${year}-${month}-${day}`: `${day}-${month}-${year}`;
    return formattedDate;
  }

  getWeekDates(increment: number = 0) {
    const today = new Date();
    const thisWeekEndDate = new Date(today);

    // Set the this week end date to the week's Saturday
    thisWeekEndDate.setDate(today.getDate() - today.getDay() + 6 + increment*7);

    // Set the this week start date to the previous week's Sunday
    const thisWeekStartDate = new Date(thisWeekEndDate);
    thisWeekStartDate.setDate(thisWeekEndDate.getDate() - 6);

    return {
      startDate: this.getFormattedDate(thisWeekStartDate.toDateString()),
      endDate: this.getFormattedDate(thisWeekEndDate.toDateString())
    };
  }

  getMonthDates(increment: number = 0) {
    const today = new Date();
    const thisMonthStartDate = new Date(today.getFullYear(), today.getMonth() + increment, 1);
    const thisMonthEndDate = new Date(today.getFullYear(), today.getMonth() + 1 + increment, 0);
  
    return {
      startDate: this.getFormattedDate(thisMonthStartDate.toDateString()),
      endDate: this.getFormattedDate(thisMonthEndDate.toDateString())
    };
  }

  getQuarterDates(increment: number = 0) {
    const today = new Date();
    const currentMonth = (12+(today.getMonth() + increment*3))%12;
    const yearChange =  Math.floor((today.getMonth() + increment*3)/12);
    let quarterStartMonth;

    // Determine the start month of the last quarter
    if (currentMonth <= 2) {
      quarterStartMonth = 1;  // January, February, March: Last quarter starts in January of the current year
    } else if (currentMonth <= 5) {
      quarterStartMonth = 4;  // April, May, June: Last quarter starts in April of the current year
    } else if (currentMonth <= 8) {
      quarterStartMonth = 7;  // July, August, September: Last quarter starts in July of the current year
    } else {
      quarterStartMonth = 10; // October, November, December: Last quarter starts in October of the current year
    }

    // Calculate the start date of the last quarter
    const lastQuarterStartDate = new Date(today.getFullYear() + yearChange, quarterStartMonth - 1, 1);

    // Calculate the end date of the last quarter
    const lastQuarterEndDate = new Date(today.getFullYear() + yearChange, quarterStartMonth + 2, 0);
    return {
      startDate: this.getFormattedDate(lastQuarterStartDate.toDateString()),
      endDate: this.getFormattedDate(lastQuarterEndDate.toDateString())
    };
  }
  getServiceOperations() {
    return new Promise((resolve, reject) => {
      this.crud.list('service-operations').then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      });
    });
  }
  updateServiceOperation(operationId: number, data: any) {
    return new Promise((resolve, reject) => {
      this.crud.put('service-operations', operationId, data).then((res: any) => {
        if (res.status === 200) {
          resolve(res);
        }
      });
    });
  }
  getStockData(e: StockCheckerFormModel): Promise<ServiceRequestItemOutput> {
    return new Promise((resolve, reject) => {
      const query_params:any = {};
      for (const key in e) {
        if (e[key as keyof StockCheckerFormModel]) {
          query_params[key] = e[key as keyof StockCheckerFormModel];
        }
      }
      this.crud.get('stock-check', '', query_params).then((res: any) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
}
