import { Injectable } from '@angular/core';
import { SharedVarsService } from '@app/global/services/shared-vars.service';
import { CrudService } from '@app/global/services/crud.service';
import { Mmid } from '@app/global/models/basic';
import { AlertsService } from './alerts.service';

@Injectable({
  providedIn: 'root',
})
export class MmidService {
  constructor(
    private sharedVars: SharedVarsService,
    private crud: CrudService,
    private alerts: AlertsService
  ) {}

  getMmidByMaterialAndVendor(materialId: number, vendorId?: number, extraParams?: any) {
    return new Promise((resolve, reject) => {
      let params:any = {
        material: materialId, 
      }
      if (vendorId){
        params.vendors = vendorId;
      }
      if (extraParams) {
        params = {
          ...params,
          ...extraParams
        }
      }
      this.crud.list('mmids', 0, params).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  getAllApprovedMmids(): Promise<any>{
    return new Promise((resolve, reject) => {
      this.crud.get('mmids/summary', '', {approved: true}).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  getStockReport(options:any, page: number = 1): Promise<any>{
    return new Promise((resolve, reject) => {
      this.crud.get('stock-report', '', {page: page, ...options}).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  getMmids(page: number,
    searchText?: string,
    sortText?: string,
    filters?: any[]){
    return new Promise((resolve, reject) => {
      let params:any = {
        ordering: sortText,
        search: searchText 
      }
      if (filters && filters.length > 0) {
        filters.forEach((filter) => {
          params[filter.key] = filter.value;
        });
      }
      this.crud.list('mmids', page, params).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  getMmidById(id: number){
    return new Promise((resolve, reject) => {
      this.crud.get('mmids', id).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  getMmidLIById(id: number){
    return new Promise((resolve, reject) => {
      this.crud.get('mmidlineitems', id).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  createMmid(data: any) {
    return new Promise((resolve, reject) => {
      this.crud.post('mmids', data).then((res) => {
        if (res.status === 201) {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  updateMmid(data: any, id: number) {
    return new Promise((resolve, reject) => {
      this.crud.put('mmids', id, data).then((res) => {
        if (res.status === 200) {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  patchMmid(mmId: number, vendorPayload: any) {
    return new Promise((resolve, reject) => {
      this.crud.patch('mmids', mmId, vendorPayload).then((res) => {
        if (res.status === 200) {
          resolve(res);
        }
      })
      .catch(async (err) => {
        if(err?.response?.data?.ERR_CODE === 'MMID_IN_USE'){
          const decision = await this.alerts.showPromisableAlert(
            'MMID in use', 
            'This MMID is in use by one or more items. Please remove the MMID from all such items before deleting it. \n\n' + 
            'If you want to limit future use of this MMID, you can BAN the MMID instead. This will not affect existing items.',
            true,
            'Yes, Ban MMID',
            'Dismiss',
            'danger',
            'medium'
          );
          if(decision){
            this.patchMmid(mmId, {ban: true}).then(() => {
              this.alerts.showToast('MMID banned successfully.');
            })
            .catch((err) => {
              this.alerts.showApiErrorToast(err, 'Error banning MMID');
            });
          }
        }
        else{
          reject(err);
        }
      });
    });
  }

  getAllMmidLineItems(): Promise<any>{
    return new Promise((resolve, reject) => {
      this.crud.list('mmidlineitems').then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
}
