import { Injectable, inject } from '@angular/core';
import { CanActivate, CanActivateFn, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '@app/global/services/auth.service';
import { SharedVarsService } from '@app/global/services/shared-vars.service';
import { SessionService } from '@app/global/services/session.service';
import { AlertsService } from '@app/global/services/alerts.service';


@Injectable({
  providedIn: 'root',
})
/**
 * This guard is used to check if the user is logged in and
 * if the session data is available.
 * If the user is logged in, he will be redirected to the projects page.
 */
export class GuardsService implements CanActivate {
  constructor(
    private auth: AuthService,
    private sharedVars: SharedVarsService,
    private sessionServ: SessionService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try{ // if logged in, redirect to projects page
      await this.auth.checkAuth(state.url);
      this.sessionServ.startSession();
      this.sharedVars.HIDE_MENU = false;
      return true;
    }
    catch(err){ // if not logged in, show login page
      this.sessionServ.stopSession();
      this.auth.logout();
      return false;
    }
  }
}

/**
 * Restrict access to modules that are not available in current version
 * @returns false
 */
export const DisabledModules : CanActivateFn = () => {
  const sharedVars = inject(SharedVarsService);
  const alerts = inject(AlertsService);
  const router = inject(Router);
  if (sharedVars.DEV_MODE){
    alerts.showToast('This module is only available in dev mode in current version', 'bottom', 3000, 'warning');
    return true;
  }
  alerts.showToast('This module is not available in current version', 'bottom', 3000, 'danger');
  router.navigate(['/materials']);
  return false;
}
/**
 * Restrict access to admin only modules
 * @returns 
 */
export const AdminGuard : CanActivateFn = () => {
  const sharedVars = inject(SharedVarsService);
  const alerts = inject(AlertsService);
  const router = inject(Router);
  const canAccessSettings = sharedVars?.USER_DETAIL?.is_superuser && sharedVars?.USER_DETAIL?.is_admin;
  if(!canAccessSettings){
    alerts.showToast('You don\'t have permission to access this page.', 'bottom', 3000, 'danger');
    router.navigate(['/materials']);
  }
  return canAccessSettings || false;
}
