<ion-content *ngIf="showPage && !auth.loggedIn">
  <div class="fullscreen-wrapper">
    <div class="left-section">
      <div class="logo">
        <img src="assets/images/fabheads-black-logo.png" alt="logo" />
        <h2>ASTRA</h2>
        <p>ERP for Fabheads</p>
      </div>
      <div *ngIf="showLoginForm">
        <div class="form" >
          <div class="title">
            <p>Sign in</p>
          </div>
          <div>
            <custom-form 
              [formId]="'loginForm'" 
              [submitButtonId]="'login-button'" 
              [formData]="loginFormData" 
              [formModel]="loginFormModel" 
              [disabled]="sharedVars.MAINTENANCE_MODE"
              [submitOnEnter]="true"
              (formSubmit)="login()">
            </custom-form>
          </div>
        </div>
        <div class="bottom">
          <p id="forgot-password-link" class="clickable link ion-text-center" (click)="showResetForm()">Forgot password</p>
        </div>
      </div>
      <div *ngIf="showResetPasswordForm">
        <div class="form" >
          <div >
            <p class="title">Request Reset</p>
            <p class="faded small ion-no-margin ion-margin-bottom">Please enter your Fabheads email to reset password.</p>
          </div>
          <div>
            <custom-form 
              [formId]="'forgotPasswordForm'" 
              [submitButtonId]="'reset-password-button'" 
              [formData]="resetPasswordFormData" 
              [formModel]="resetPasswordFormModel" 
              (formSubmit)="sendResetRequest($event)">
            </custom-form>
          </div>
        </div>
        <div class="bottom">
          <p class="clickable link ion-text-center" (click)="backToLogin()">Back to Login</p>
        </div>
      </div>
    </div>
    <div class="right-section">

    </div>
  </div>
  <div class="global-banner" *ngIf="sharedVars.MAINTENANCE_MODE">
    <div class="content">
      <p class="ion-text-center">ERP is currently under maintenance. Please try again later</p>
    </div>
  </div>
</ion-content>